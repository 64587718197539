import { useSetAtom } from 'jotai';
import {
    uiStateAtomLoginModalAuthSource,
    uiStateAtomLoginModalOpened,
} from '~/modules-rocco/root/states/ui-state-login-modal-opened';
import type { AuthSource } from '~/modules/user/type';
import { useTracker } from '@archipro-website/tracker';
import { useCallback } from 'react';

/**
 * Custom hook to manage the state of the login modal.
 *
 * This hook provides a function to open or close the login modal and set the authentication source.
 * It also logs the login attempt using the tracker.
 */
export const useSetLoginModal = () => {
    const setLoginModalOpened = useSetAtom(uiStateAtomLoginModalOpened);
    const setLoginModalAuthSource = useSetAtom(uiStateAtomLoginModalAuthSource);
    const tracker = useTracker();
    const setLoginModal = useCallback(
        ({
            authSource,
            isOpen,
        }: {
            authSource: AuthSource;
            isOpen: boolean;
        }) => {
            setLoginModalOpened(isOpen);
            setLoginModalAuthSource(authSource);
            tracker.log('accountLogin', {
                url: new URL(window.location.href),
                data: {
                    step: 1,
                },
            });
        },
        [setLoginModalOpened, setLoginModalAuthSource, tracker]
    );

    return { setLoginModal };
};
